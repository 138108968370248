import { selector, selectorFamily } from "@geome/recoil"
import { PTVRequest, PTVResponse } from "../../types"
import {
  PTVRegionAtom,
  PTVLanguageAtom,
  PTVRequestUrlAtom,
  currentPTVRequestBodyAtom,
  truckRouteOptionsAtom,
  vehicleTypeAtom,
  PTVUnitAtom,
} from "../atoms"
import { directionsOptionsAtom } from "@geome/recoil/dist/feature/directions/atoms"

export const PTVRequestOptionsSelector = selector<PTVRequest["options"]>({
  key: "PTVRequestOptionsSelector",
  get: ({ get }) => {
    const vehicleType = get(vehicleTypeAtom)
    const truckRouteOptions = get(truckRouteOptionsAtom)
    const language = get(PTVLanguageAtom)
    const region = get(PTVRegionAtom)
    const unit = get(PTVUnitAtom)
    const directionsOptions = get(directionsOptionsAtom)

    const truckOptions = Object.fromEntries(
      Object.entries(truckRouteOptions).map(([key, value]) => {
        switch (key) {
          case "width":
          case "length":
          case "height":
            // convert from meter to cm
            return [key, (value as number) * 100]
          default:
            return [key, value]
        }
      })
    )

    switch (vehicleType) {
      case "car":
        return {
          route: {
            language,
            avoid_highways: directionsOptions.avoid_highways,
            avoid_toll_roads: directionsOptions.avoid_tolls,
          },
        }

      case "truck":
        return {
          route: {
            language,
            region,
            unit,
            avoid_highways: directionsOptions.avoid_highways,
            avoid_toll_roads: directionsOptions.avoid_tolls,
          },
          vehicle: {
            ...(truckOptions as PTVRequest["options"]["vehicle"]),
            profile: "truckfast",
          },
        }
    }
  },
})

export const PTVDirectionsSelectorFamily = selectorFamily<PTVResponse, PTVRequest>({
  key: "PTVDirectionsSelectorFamily",
  get:
    (body) =>
    async ({ get }) => {
      const url = new URL(get(PTVRequestUrlAtom))
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-type": "application/json" },
      })
      return (await response.json()) satisfies PTVResponse
    },
})

export const currentPTVResponseSelector = selector<null | PTVResponse>({
  key: "currentPTVResponseSelector",
  get: ({ get }) => {
    const body = get(currentPTVRequestBodyAtom)
    if (!body) return null
    return get(PTVDirectionsSelectorFamily(body))
  },
})

export const PTVHasErroredSelector = selector<boolean>({
  key: "PTVHasErroredSelector",
  get: ({ get }) => {
    const reponse = get(currentPTVResponseSelector)
    return Boolean(reponse !== null && reponse.errors)
  },
})
